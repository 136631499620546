'use-client';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { useTPAGallery } from '../../../providers/useTPAGallery/useTPAGallery';
import type { TPAGalleryProps } from '../TPAGallery.types';
import styles from './style/TPAGallery.scss';
import { isValidUrl } from '@wix/editor-elements-corvid-utils';

const updateCompIdInURLForRepeaterUseCase = (url: string, id: string) => {
  const urlObject = new URL(url);
  const urlCompId = urlObject.searchParams.get('compId');
  if (urlCompId && urlCompId !== id) {
    urlObject.searchParams.set('compId', id);
  }
  return urlObject.toString();
};

const TPAGallery: React.FC<TPAGalleryProps> = props => {
  const {
    id,
    className,
    url,
    images,
    quality,
    mainPageId,
    compProps,
    compStyles,
    heightOverride,
    isScreenWidth,
    componentReady,
    forwardRef,
    onUnMount = () => {},
    onMouseEnter,
    onMouseLeave,
    isPlayingAllowed,
    readyReportsCount,
  } = props;

  const updatedUrl = React.useMemo(() => {
    if (isValidUrl(url)) {
      const urlWithUpdatedCompId = updateCompIdInURLForRepeaterUseCase(url, id);
      return urlWithUpdatedCompId;
    }
    return url;
  }, [id, url]);

  function getStyleOverrides() {
    return heightOverride
      ? {
          height: `${heightOverride}px`,
        }
      : {};
  }

  const ref = useTPAGallery(forwardRef, {
    componentReady,
    compProps,
    compStyles,
    quality,
    images,
    mainPageId,
    isPlayingAllowed,
    readyReportsCount,
  });

  const fitToScreenWidth = compProps?.fitToScreenWidth === false ? false : true;
  React.useEffect(() => {
    return onUnMount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <wix-iframe
      id={id}
      {...getDataAttributes(props)}
      className={className}
      data-src={updatedUrl}
      style={getStyleOverrides()}
      class={isScreenWidth && fitToScreenWidth ? styles.screenWidth : ''}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        style={getStyleOverrides()}
        className={styles.iframe}
        ref={ref}
        data-src={updatedUrl}
        scrolling="no"
      />
    </wix-iframe>
  );
};

export default TPAGallery;
