'use-client';
import * as React from 'react';
import type { MasonryProps } from '../Masonry.types';
import TPAGallery from '../../TPAGallery/viewer/TPAGallery';

const Masonry: React.FC<MasonryProps> = props => {
  return <TPAGallery {...props}></TPAGallery>;
};

export default Masonry;
